<template>
    <div class="regulartext">
    <p>Founded in 2008 by two college friends and later reinforced by a third after his stint in the AAA arena, Noumenon Games is a hermit game studio based in sweden that enjoys exploring game design.</p>
    <p>We also do a lot of outsourcing on the side, supporting other studios with programming, 3D animation and art, game design, and level design. Among the studios we've worked with are Avalanche Studios, Tarsier Studios, The Station and Frictional Games.
 Feel free to <router-link to="/contact">contact</router-link> us if you’re looking for extra people. </p>
  </div>
  <img src="/img/selection.png" />
  <div class="regulartext footer_img">Selection of various in house projects</div>
  <br />
  <div class="person">
    <img src="/img/sebbe.png" />
    <div class="headertext centered">Sebastian Karlsson</div>
    <div class="regulartext centered">Main programming, game design</div>
    <div class="regulartext centered">Over 15 years of professional programming experience, originally starting with web development and then transitioning to the games industry in 2008 when landing his first industry job at Tarsier Studios. Extensive experience in C++, mostly on engine related work, but the last couple of years almost solely focused on Unity & C#. On the console end of things have worked on projects on the PS3 & Switch, latest Switch project being Snakebird Complete released at the end of 2023. Also developed & released games on Android & iOS.</div>
  </div>
  <div class="person">
    <img src="/img/felix.png" />
    <div class="headertext centered">Felix Eliasson</div>
    <div class="regulartext centered">Game, level and art design, additional programming</div>
    <div class="regulartext centered">Been in the industry professionally since 2008, but have developed games even further back as a hobbyist. Primary focus on game design, level design & art, but also have some experience with programming, mainly in Unity & Game maker. Competent in tools such as Maya, Photoshop & Aseprite.</div>
  </div>
  <div class="person">
    <img src="/img/jolle.png" />
    <div class="headertext centered">Joel Astbo</div>
    <div class="regulartext centered">Animation, additional art and programming</div>
    <div class="regulartext centered">Professionaly been in the industry for 15 years, starting his career at Avalanche Studios working games such as Just Cause 2 & Mad Max. While primarily working as an animator at the start of his career is also competent most areas of 3D art production. No stranger to programming & a problem solver which can take 3D assets all the way from the drawing board to actual implementation. Competent in tools such as Maya, MotionBuilder, Photoshop. Joined Noumenon Games around 2013.</div>
  </div>
  <p class="regulartext centered">Disclaimer: Studio hat phase has come & gone.</p>
</template>


<style lang="scss" scoped>

  .centered
  {
    text-align: center;
  }
  .footer_img
  {
    font-size: 12px;
    text-align: center;
  }
  img
  {
    margin: 0 auto;
    display: block;
    width: 25%;
  }
  .person
  {
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 900px;
    img
    {
      width : 100px;
    }
  }
</style>
